import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/core/styles";
import { useAlert } from "react-alert";
import {
    TextField,
    MenuItem,
    FormControl,
    InputLabel,
    OutlinedInput,
    Select,
    Button,
} from "@material-ui/core";
import { useHistory, useRouteMatch } from "react-router-dom";
import QRCode from "qrcode.react";
import PrintIcon from '@material-ui/icons/Print';

import { useSubheader } from "../../../../_metronic/layout";
import * as PatientService from "../../../services/PatientService";
import * as AlertService from "../../../AlertService";
import * as Constants from "../../../Constants";
import SectionHeader from "../../../components/layout/SectionHeader";
import { useTeams } from "../../Team";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import useIsNew from "../../../hooks/UseIsNew";
import usePatient from "../hooks/UsePatient";
import {useProfessions} from "../../Profession";
import { Col, Row } from "react-bootstrap";
import FormErrorBlock from "../../../components/form-control/FormErrorBlock";
import {KTUtil} from "../../../../_metronic/_assets/js/components/util";
import useUser from "../../../hooks/UseUser";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function PatientNew({ intl, ...props }) {
    //state
    const [isNewPatient, existingPatientId] = useIsNew("/patient-new", "/patient-edit/:id");
    const alert = useAlert();
    const history = useHistory();
    const subheader = useSubheader();
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    const teams = useTeams();
    const professions = useProfessions();
    const patient = usePatient(existingPatientId);
    const isLoading = ((isNewPatient && (!teams || !professions)) || (!isNewPatient && (!teams || !professions || !patient)));
    const qrCode = {'qr': patient?.qrCode,'env': Constants.ENVIRONMENT}
    const user = useUser();

    // effects

    useEffect(() => {
        async function populateFields() {
            if (teams && professions) {
                if (patient) {
                    formik.setFieldValue('lastName', patient.lastName);
                    formik.setFieldValue('firstName', patient.firstName);
                    formik.setFieldValue('gender', patient.gender);
                    formik.setFieldValue('birthDate', patient.birthDate);
                    formik.setFieldValue('phone', patient.phone);
                    formik.setFieldValue('email', patient.email);
                    formik.setFieldValue('voipUser', patient.voipUser);
                    let team = teams.find(team => team.id === patient.team);
                    if (team !== undefined) {
                        formik.setFieldValue("team", team);
                    }
                    let profession = professions.find(pro => pro.id === patient.profession);
                    if (profession !== undefined) {
                        formik.setFieldValue("profession", profession);
                    }
                } else {
                    formik.resetForm();
                }
            }
        }
        populateFields();
    }, [teams, patient, professions]);

    useEffect(() => {
        if (isNewPatient){
            subheader.setTitle(intl.formatMessage({ id: "PAGES.NEW_PATIENT" }));
        } else {
            subheader.setTitle(intl.formatMessage({ id: "PAGES.EDIT_PATIENT" }));
        }
    });

    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    // handlers
    async function handleSubmit(values) {
        let send = {
            ...values,
            profession: values.profession.id,
            team: values.team?.id || null,
            fiscalCode: KTUtil.hash(values.firstName + values.lastName + values.gender + values.birthDate) + "",
        }
        let result;
        let success = false;
        if (isNewPatient) {
            result = await PatientService.addPatient(send);
            if (result.status === Constants.STATUTS_201) {
                let patient = await result.json();
                if (user.role === Constants.ROLE_DOC || user.role === Constants.ROLE_PHYSIO) {
                    history.push("patient-monitored-list");
                } else if (user.role === Constants.ROLE_ADMIN) {
                    history.push(`/patient-edit/${patient.id}`);
                }
                success = true;
            }
        } else {
            result = await PatientService.updatePatient(existingPatientId, send);
            if (result.status === Constants.STATUTS_200) {
                success = true;
            }
        }

        if (success) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await result.json()).message);
        }
    }

    //formik
    const newPatientSchema = Yup.object().shape({
        firstName: Yup.string().required(
            intl.formatMessage({ id: "ERRORS.REQUIRED_FIELD" })
        ),
        lastName: Yup.string().required(
            intl.formatMessage({ id: "ERRORS.REQUIRED_FIELD" })
        ),
        birthDate: Yup.date().required(
            intl.formatMessage({ id: "ERRORS.REQUIRED_FIELD" })
        ),
        gender: Yup.string().required(
            intl.formatMessage({ id: "ERRORS.REQUIRED_FIELD" })
        ),
        phone: Yup.string().required(
            intl.formatMessage({ id: "ERRORS.REQUIRED_FIELD" })
        ),
        email: Yup.string()
            .email(intl.formatMessage({ id: "ERRORS.WRONG_EMAIL_FORMAT" }))
            .required(intl.formatMessage({ id: "ERRORS.REQUIRED_FIELD" })),
        voipUser: Yup.string().required(
            intl.formatMessage({ id: "ERRORS.REQUIRED_FIELD" })
        ),
        team: Yup.object().nullable(),
        profession: Yup.object().nullable().required(
            intl.formatMessage({ id: "ERRORS.REQUIRED_FIELD" })
        ),
    });

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            birthDate: "",
            gender: "Maschio",
            phone: "",
            email: "",
            voipUser: "0",
            team: null,
            profession: null,
        },
        validationSchema: newPatientSchema,
        onSubmit: values => {
            handleSubmit(values);
        },
    });

    return (
        <div
            className="card card-custom gutter-b"
            style={{ background: "#fff", color: "#333" }}
        >
            {isLoading && <LoadingSpinner />}

            <form onSubmit={formik.handleSubmit}>
                <div className="card-body">
                    <SectionHeader messageId="GENERAL.STRUCTUR" />
                    <div className="row d-print-none">
                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({
                                    id: "FIELD.LASTNAME",
                                })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="lastName"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("lastName")}
                                // value={formik.values.lastName}
                            />
                            {formik.touched.lastName &&
                            formik.errors.lastName ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.lastName}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({
                                    id: "FIELD.FIRSTNAME",
                                })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="firstName"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("firstName")}
                                // value={formik.values.firstName}
                            />
                            {formik.touched.firstName &&
                            formik.errors.firstName ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.firstName}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <FormControl
                                margin="normal"
                                size="small"
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel
                                    ref={inputLabel}
                                    htmlFor="outlined-gender-simple"
                                >
                                    <FormattedMessage id="FIELD.GENDER" /> *
                                </InputLabel>
                                <Select
                                    name="gender"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("gender")}
                                    // value={formik.values.gender}
                                    input={
                                        <OutlinedInput
                                            labelWidth={labelWidth}
                                            name="gender"
                                            id="outlined-gender-simple"
                                        />
                                    }
                                >
                                    <MenuItem value={"Maschio"}>
                                        <FormattedMessage id="FIELD.TYPE_GENDER_MALE" />
                                    </MenuItem>
                                    <MenuItem value={"Femmina"}>
                                        <FormattedMessage id="FIELD.TYPE_GENDER_FEMALE" />
                                    </MenuItem>
                                </Select>
                            </FormControl>
                            {formik.touched.gender && formik.errors.gender ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.gender}
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="date"
                                label={`${intl.formatMessage({
                                    id: "FIELD.BIRTH_DATE",
                                })} *`}
                                type="date"
                                // defaultValue="1980-01-01"
                                variant="outlined"
                                size="small"
                                margin="normal"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="birthDate"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("birthDate")}
                                // value={formik.values.birthDate}
                            />
                            {formik.touched.birthDate &&
                            formik.errors.birthDate ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.birthDate}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <Row>
                        <Col md={6}>
                            <FormikAutocomplete
                                options={teams || []}
                                fieldName="team"
                                labelName="name"
                                formik={formik}
                                label={intl.formatMessage({ id: "FIELD.TEAM" })}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <FormErrorBlock
                                fieldName="team"
                                formik={formik}
                            />
                        </Col>
                        <Col md={6}>
                            <FormikAutocomplete
                                options={professions || []}
                                fieldName="profession"
                                labelName="name"
                                formik={formik}
                                label={intl.formatMessage({ id: "FIELD.PROFESSION" }) + " *"}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <FormErrorBlock
                                fieldName="profession"
                                formik={formik}
                            />
                        </Col>
                    </Row>
                    <SectionHeader messageId="GENERAL.CONTACTS" />
                    <div className="row d-print-none">
                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({
                                    id: "USER.EMAIL",
                                })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="email"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("email")}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.email}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({
                                    id: "FIELD.PHONE",
                                })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="phone"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("phone")}
                                // value={formik.values.phone}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.phone}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    <div className="row d-print-none">
                        <div className="col-sm-6 col-md-4">
                            <TextField
                                hidden
                                id="outlined-normal"
                                label={`${intl.formatMessage({
                                    id: "FIELD.VOIP_INTERNAL",
                                })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="voipUser"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("voipUser")}
                            />
                            {formik.touched.voipUser &&
                            formik.errors.voipUser ? (
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                        {formik.errors.voipUser}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {!isNewPatient && 
                        <>
                            <SectionHeader messageId="GENERAL.QR_CODE" />
                            <div className="row d-print-none">
                                <div className="col-md-4">
                                    <QRCode
                                        value={JSON.stringify(qrCode)}
                                        size={290}
                                        level={"H"}
                                        includeMargin={true}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    <div className="row d-none d-print-block">
                        <SectionHeader messageId="PRINT_QR.PATIENT_QR_CODE" />
                        <div className="col-12">
                            <p>
                                <strong>
                                    <FormattedMessage id="PRINT_QR.PATIENT_FULLNAME" />
                                    :
                                </strong>{" "}
                                {formik.values.lastName}{" "}
                                {formik.values.firstName}
                            </p>
                            <p>
                                <strong>
                                    <FormattedMessage id="GENERAL.QR_CODE" />:
                                </strong>{" "}
                                {qrCode.qr}
                            </p>
                            <QRCode
                                value={JSON.stringify(qrCode)}
                                size={290}
                                level={"H"}
                                includeMargin={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-footer py-4 d-print-none">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        disabled={!formik.isValid || !formik.dirty}
                    >
                        <FormattedMessage id="ACTIONS.SAVE" />
                        <SaveIcon
                            className={clsx(
                                classes.rightIcon,
                                classes.iconSmall
                            )}
                        />
                    </Button>

                    <Button
                        type="button"
                        variant="contained"
                        color="inherit"
                        size="small"
                        className={classes.button}
                        onClick={() => {
                            window.print();
                        }}
                    >
                        <FormattedMessage id="ACTIONS.PRINT" />
                        <PrintIcon
                            className={clsx(
                                classes.rightIcon,
                                classes.iconSmall
                            )}
                        />
                    </Button>

                    <span className="text-muted float-right mt-4">
                        * <FormattedMessage id="GENERAL.REQUIRED_FIELDS" />
                    </span>
                </div>
            </form>
        </div>
    );
}

export default injectIntl(PatientNew);
