import { Toolbar, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";

import { PhysiotherapyFollowUpTable } from "../modules/Dashboard";
import * as Constants from "../Constants";
import * as LiveService from "../services/LiveService";

function PhysiotherapistDashboardPage() {

    const [followUpRows, setFollowUpRows] = useState([]);

    useEffect(() => {
        const onChangeData = (type, payload) => {
            switch (type) {
                case Constants.DASHBOARD_PHYSIOTHERAPY_FOLLOW_UP:
                    setFollowUpRows(payload);
                    break;
                default:
                    break;
            }
        }

        const onChangeAlertParameters = (payload) => {}

        const socket = LiveService.liveDashboardChannel(onChangeData, onChangeAlertParameters);
        return () => { socket.close(); }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col className="gutter-b">
                <Card className="bg-white p-2">
                    <Toolbar>
                        <Typography variant="h6" id="tableTitle" component="div">
                            <div className="row">
                                <FormattedMessage id="DASHBOARD.TABLE_TITLE" />
                            </div>
                            <div className="row">
                                <span className="text-muted"><FormattedMessage id="DASHBOARD.TABLE_SUBTITLE" /></span>
                            </div>
                        </Typography>
                    </Toolbar>
                    <PhysiotherapyFollowUpTable rows={followUpRows}/>
                </Card>
            </Col>
        </Row>
    )
}
export default injectIntl(PhysiotherapistDashboardPage);
