import * as Constants from "../Constants";
import { getAPIIncludeAuth } from "./Shared";

export async function getAllInjuryLocations() {
    const api = Constants.BACKEND_URL + Constants.API_INJURY_LOCATION + "?paginationEnabled=false";
    return getAPIIncludeAuth(api);
}

export async function findInjuryLocationById(id) {
    const api = Constants.BACKEND_URL + Constants.API_INJURY_LOCATION+`/${id}`;
    return getAPIIncludeAuth(api);
}
