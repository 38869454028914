import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";

import { useLang } from "../../../../_metronic/i18n";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function PhysiotherapyFollowUpTable({rows}) {

    const locale = useLang();

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><FormattedMessage id="DASHBOARD.TABLE_COLUMN_PATIENT_FULLNAME" /></TableCell>
                        <TableCell align="left"><FormattedMessage id="DASHBOARD.TABLE_COLUMN_VISIT_DATETIME" /></TableCell>
                        <TableCell align="left"><FormattedMessage id="DASHBOARD.TABLE_COLUMN_FOLLOW_UP_DATETIME" /></TableCell>
                        <TableCell align="center"><FormattedMessage id="DASHBOARD.TABLE_COLUMN_FOLLOW_UP_VISIT" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row._id} >
                            <TableCell>{`${row.patient.firstName} ${row.patient.lastName}`}</TableCell>
                            {/*could also use a FormattedDate and a FormattedTime from Format.JS/intl*/}
                            <TableCell>{new Date(row.creationDate).toLocaleString(locale)}</TableCell>
                            <TableCell>{new Date(row.notifyTime).toLocaleString(locale)}</TableCell>
                            <TableCell align="center">
                                <Link
                                    to={`/physiotherapy-visit/${row.patient._id}?follows=${row._id}&previousLocation=${row.injury.injuryLocationId}&previousClass=${row.injury.injuryClassId}`}
                                    className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                >
                                    <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG
                                            src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-check.svg")}
                                        />
                                    </span>
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
