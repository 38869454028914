import * as Constants from "../Constants";

import {getAPIIncludeAuth} from "./Shared";

export async function getAllPathologies() {
    const api = Constants.BACKEND_URL + Constants.API_PATHOLOGY + "?paginationEnabled=false";

    return getAPIIncludeAuth(api);
}

export async function getAcutePathologies() {
    const api = Constants.BACKEND_URL + Constants.API_PATHOLOGY + "?paginationEnabled=false&chronic=false";
    return getAPIIncludeAuth(api);
}

export async function getChronicPathologies() {
    const api = Constants.BACKEND_URL + Constants.API_PATHOLOGY + "?paginationEnabled=false&chronic=true";
    return getAPIIncludeAuth(api);
}

export async function getPathology(id) {
    const api = Constants.BACKEND_URL + Constants.API_PATHOLOGY + `/${id}`;
    return getAPIIncludeAuth(api);
}
