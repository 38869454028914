import React from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import * as Yup from "yup"
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { Card, Col, Row } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import { TextField, FormControlLabel, Switch, Button } from "@material-ui/core";
import { Save } from "@material-ui/icons";

import useIsNew from "../../../hooks/UseIsNew";
import { useQueryParams } from "../../../hooks/UseQueryParams";
import SectionHeader from "../../../components/layout/SectionHeader";
import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import { FormikErrorComponent } from "../../../components/form-control/FormikErrorComponent";
import { useInjuryClass, useInjuryClasses } from "../../InjuryClass";
import { useInjuryLocation, useInjuryLocations } from "../../InjuryLocation";
import * as PhysiotherapyVisitService from "../../../services/PhysiotherapyVisitService";
import * as Constants from "../../../Constants";
import * as AlertService from "../../../AlertService";

function PhysiotherapyVisitPage() {

    const [, patientId] = useIsNew("", "/physiotherapy-visit/:id");
    const history = useHistory()
    const alert = useAlert();
    const queryParameters = useQueryParams();
    const intl = useIntl();
    const injuryClasses = useInjuryClasses();
    const injuryLocations = useInjuryLocations();
    const previousLocation = useInjuryLocation(queryParameters.get("previousLocation"));
    const previousClass = useInjuryClass(queryParameters.get("previousClass"));
    
    const formSchema = Yup.object().shape({
        findings: Yup.string().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        injuryClass: Yup.object().nullable().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        injuryLocation: Yup.object().nullable().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        date: Yup.string(),
        needsFollowUp: Yup.boolean().required(),
        notifyTime: Yup.string().when("needsFollowUp", {
            is: true,
            then: Yup.string().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
            otherwise: Yup.string(),
        }),
        follows: Yup.string(),
    });

    async function handleSubmit(values) {
        let send = {
            ...values,
            injuryClass: values.injuryClass.id,
            injuryLocation: values.injuryLocation.id,
            date: values.date ? new Date(values.date).toISOString() : undefined,
            notifyTime: values.needsFollowUp ? new Date(values.notifyTime).toISOString() : undefined,
        }
        let result;
        let success = false;
        result = await PhysiotherapyVisitService.addPhysiotherapyVisit(patientId, send);
        if (result.status === Constants.STATUTS_201) {
            history.push("/patient-monitored-list");
            success = true;
        }

        if (success) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await result.json()).message);
        }
    }

    return (
        <Card>
            <Formik
                initialValues={{
                    findings: "",
                    injuryClass: previousClass || null,
                    injuryLocation: previousLocation || null,
                    date: "",
                    needsFollowUp: false,
                    notifyTime: "",
                    follows: queryParameters.get("follows") || "",
                }}
                validationSchema={formSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Card.Body>
                            <SectionHeader messageId="GENERAL.PHYSICAL_EXAMINATION"/>
                            <Row>
                                <Col md={4}>
                                    <FormikAutocomplete
                                        options={injuryClasses || []}
                                        fieldName="injuryClass"
                                        formik={formik}
                                        labelName="name"
                                        label={intl.formatMessage({ id: 'FIELD.CLASS' })}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled={Boolean(previousClass)}
                                    />
                                    <ErrorMessage name={"injuryClass"} component={FormikErrorComponent}/>
                                </Col>
                                <Col md={8}>
                                    <FormikAutocomplete
                                        options={injuryLocations || []}
                                        fieldName="injuryLocation"
                                        formik={formik}
                                        labelName="name"
                                        label={intl.formatMessage({ id: 'FIELD.INJURY_LOCATION' })}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled={Boolean(previousLocation)}
                                    />
                                    <ErrorMessage name={"injuryLocation"} component={FormikErrorComponent}/>
                                </Col>
                                <Col md={12}>
                                    <TextField
                                        label={intl.formatMessage({id: "FIELD.FINDINGS"})}
                                        {...formik.getFieldProps("findings")}
                                        multiline
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"findings"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <SectionHeader messageId="GENERAL.DATE"/>
                            <Row>
                                <Col md={4}>
                                    <TextField
                                        type={"datetime-local"}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        {...formik.getFieldProps("date")}
                                    />
                                    <ErrorMessage name={"date"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <SectionHeader messageId="GENERAL.FOLLOWUP"/>
                            <Row>
                                <Col md={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formik.values.needsFollowUp}
                                                name="needsFollowUp"
                                                onChange={formik.handleChange}
                                                color="primary"
                                                margin="normal"
                                            />
                                        }
                                        label={intl.formatMessage({id: "FIELD.FOLLOWUP"})}
                                    />
                                </Col>
                                <Col md={4}>
                                    <TextField
                                        type={"datetime-local"}
                                        disabled={!formik.values.needsFollowUp}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        {...formik.getFieldProps("notifyTime")}
                                    />
                                    <ErrorMessage name={"notifyTime"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={formik.isSubmitting}
                                endIcon={<Save/>}
                            >
                                <FormattedMessage id="ACTIONS.SAVE" />
                            </Button>
                        </Card.Footer>
                    </form>
                )}
            </Formik>
        </Card>
    )
}

export default injectIntl(PhysiotherapyVisitPage);
