import * as Constants from "../Constants";
import { postAPIIncludeAuth } from "./Shared";

export async function addPhysiotherapyVisit(patientId, physiotherapyVisit) {
    const api = Constants.BACKEND_URL + Constants.API_PHYSIOTHERAPY_VISIT + `/${patientId}`;
    return postAPIIncludeAuth(
        api,
        JSON.stringify(physiotherapyVisit),
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    );
}
