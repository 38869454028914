/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import AsideMenuListSectionHeader from "../../../../../app/components/layout/AsideMenuListSectionHeader";
import AsideMenuListLink from "../../../../../app/components/layout/AsideMenuListLink";
import * as Constants from "../../../../../app/Constants";
import useCurrentGrandPrix from "../../../../../app/modules/GrandPrix/hooks/useCurrentGrandPrix";
import RolesFilter from "../../../../../app/components/layout/RolesFilter";

export function AsideMenuList({ layoutProps }) {

  const activeGrandPrix = useCurrentGrandPrix();

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        <li className="menu-section ">
          <h4 className="menu-text">{activeGrandPrix?.name}</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        
        <AsideMenuListLink
          location="/dashboard"
          iconPublicPath="/media/svg/icons/Home/Home.svg"
          messageId="MENU.DASHBOARD"
        />

        <AsideMenuListSectionHeader messageId="MENU.PATIENT_DIVIDER"/>
        
        <RolesFilter roles={[Constants.ROLE_DOC, Constants.ROLE_ADMIN, Constants.ROLE_PHYSIO]}>          
        <AsideMenuListLink
          location="/patient-new"
          iconPublicPath="/media/svg/icons/Communication/Add-user.svg"
          messageId="MENU.NEW_PATIENT"
        />
        </RolesFilter>
        
        <AsideMenuListLink
          location="/patient-monitored-list"
          iconPublicPath="/media/svg/icons/Communication/Adress-book2.svg"
          messageId="MENU.PATIENT_MONITORED_LIST"
        />

        <RolesFilter roles={[Constants.ROLE_DOC]}>
        
        <AsideMenuListSectionHeader messageId="MENU.MONITORING_DIVIDER"/>

        <AsideMenuListLink
          location="/patient-live-monitoring"
          iconPublicPath="/media/svg/icons/Shopping/Barcode-scan.svg"
          messageId="MENU.PATIENT_LIVE_MONITORING"
        />

        <AsideMenuListLink
          location="/patient-historic-monitoring"
          iconPublicPath="/media/svg/icons/Code/Time-schedule.svg"
          messageId="MENU.PATIENT_HISTORIC_MONITORING"
        />
        </RolesFilter>

        <RolesFilter roles={[Constants.ROLE_ADMIN]}>
        <AsideMenuListSectionHeader messageId="MENU.DOCTOR_DIVIDER"/>

        <AsideMenuListLink
          location="/doctor-new"
          iconPublicPath="/media/svg/icons/Communication/Add-user.svg"
          messageId="MENU.DOCTOR_NEW"
        />

        <AsideMenuListLink
          location="/doctor-list"
          iconPublicPath="/media/svg/icons/Communication/Adress-book2.svg"
          messageId="MENU.DOCTOR_LIST"
        />

        <AsideMenuListSectionHeader messageId="MENU.PHYSIOTHERAPIST_DIVIDER"/>

        <AsideMenuListLink
          location="/physiotherapist-new"
          iconPublicPath="/media/svg/icons/Communication/Add-user.svg"
          messageId="MENU.PHYSIOTHERAPIST_NEW"
        />

        <AsideMenuListLink
          location="/physiotherapist-list"
          iconPublicPath="/media/svg/icons/Communication/Adress-book2.svg"
          messageId="MENU.PHYSIOTHERAPIST_LIST"
        />

        <AsideMenuListSectionHeader messageId="MENU.TEAMS_DIVIDER"/>

        <AsideMenuListLink
          location="/team-new"
          iconPublicPath="/media/svg/icons/Communication/Add-user.svg"
          messageId="MENU.TEAM_NEW"
        />

        <AsideMenuListLink
          location="/team-list"
          iconPublicPath="/media/svg/icons/Communication/Adress-book2.svg"
          messageId="MENU.TEAM_LIST"
        />

        <AsideMenuListSectionHeader messageId="MENU.GRAND_PRIX_DIVIDER"/>

        <AsideMenuListLink
          location="/grand-prix-new"
          iconPublicPath="/media/svg/icons/Communication/Add-user.svg"
          messageId="MENU.GRAND_PRIX_NEW"
        />

        <AsideMenuListLink
          location="/grand-prix-list"
          iconPublicPath="/media/svg/icons/Communication/Adress-book2.svg"
          messageId="MENU.GRAND_PRIX_LIST"
        />

        </RolesFilter>

        <AsideMenuListSectionHeader messageId="MENU.REPORT_DIVIDER"/>

        <RolesFilter roles={[Constants.ROLE_DOC]}>
        <AsideMenuListLink
          location="/report-new"
          iconPublicPath="/media/svg/icons/Communication/Add-user.svg"
          messageId="MENU.GENERATE_MEDICAL_REPORT"
        />

        <AsideMenuListLink
          location="/report-list"
          iconPublicPath="/media/svg/icons/Communication/Adress-book2.svg"
          messageId="MENU.MEDICAL_REPORT_LIST"
        />
        </RolesFilter>{/*Doctor*/}

        <RolesFilter roles={[Constants.ROLE_DOC, Constants.ROLE_PHYSIO]}>
        <AsideMenuListLink
          location="/physiotherapy-report-new"
          iconPublicPath="/media/svg/icons/Communication/Add-user.svg"
          messageId="MENU.GENERATE_PHYSIOTHERAPY_REPORT"
        />

        <AsideMenuListLink
          location="/physiotherapy-report-list"
          iconPublicPath="/media/svg/icons/Communication/Adress-book2.svg"
          messageId="MENU.PHYSIOTHERAPY_REPORT_LIST"
        />
        </RolesFilter>{/*Doctor,Physio*/}

        <RolesFilter roles={[Constants.ROLE_ADMIN]}>
        <AsideMenuListLink
          location="/global-report-new"
          iconPublicPath="/media/svg/icons/Communication/Add-user.svg"
          messageId="MENU.GENERATE_GLOBAL_REPORT"
        />
        <AsideMenuListLink
          location="/global-report-list"
          iconPublicPath="/media/svg/icons/Communication/Adress-book2.svg"
          messageId="MENU.GLOBAL_REPORT_LIST"
        />
        </RolesFilter>
        
        <AsideMenuListSectionHeader messageId="MENU.USER_DOCUMENT_DIVIDER"/>

        <AsideMenuListLink
          location="/documents-list"
          iconPublicPath="/media/svg/icons/Home/Book.svg"
          messageId="MENU.USER_DOCUMENT_LIST"
        />

        <RolesFilter roles={[Constants.ROLE_ADMIN]}>
        <AsideMenuListSectionHeader messageId="MENU.GENERAL_SETTINGS_DIVIDER"/>

        <AsideMenuListLink
          location="/notifications"
          iconPublicPath="/media/svg/icons/Code/Warning-1-circle.svg"
          messageId="MENU.ALERT_CUSTOMIZATION"
        />
  
        <AsideMenuListLink
          location="/download-apps"
          iconPublicPath="/media/svg/icons/Files/Cloud-Download.svg"
          messageId="MENU.ALERT_DOWNLOAD_APPS"
        />
        </RolesFilter>

        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/error"
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")}
              />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.SETTINGS" /></span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Configurazioni</span>
                </span>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/error/error-v1")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v1">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text"><FormattedMessage id="MENU.STRUCTURAL_REGISTRY" /></span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/error/error-v2")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v2">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text"><FormattedMessage id="MENU.USER_MANAGEMENT" /></span>
                </NavLink>
              </li>
              
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v3")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v3">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text"><FormattedMessage id="MENU.SENSORS" /></span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/error/error-v3")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v3" rel='noopener noreferrer' download>
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text"><FormattedMessage id="MENU.DOWNLOAD_APP" /></span>
                </NavLink>
              </li>

            </ul>
          </div>
        </li> */}
      </ul>

    </>
  );
}
