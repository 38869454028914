import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";

import usePagination from "../../../hooks/UsePagination";
import * as PhysiotherapyService from "../../../services/PhysiotherapistService";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import * as Constants from "../../../Constants";

function PhysiotherapistList() {
    
    const {commit, page, rows, setPage, totalElements} = usePagination(PhysiotherapyService.findPhysiotherapistsByFilter);

    useEffect(() => {
        async function firstSearch() {
            commit();
        }
        firstSearch();
    }, [commit]);

    function handleChangePage(event, newPage) {
        setPage(newPage);
        commit();
    }

    return (
        <Card>
            <Card.Body>
                <TableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_LASTNAME" /></TableCell>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_FIRTSTNAME" /></TableCell>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_EMAIL" /></TableCell>
                                <TableCell align="left"><FormattedMessage id="TABLE.HEADER_ACTIONS" /></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="left">{row.lastName}</TableCell>
                                    <TableCell align="left">{row.firstName}</TableCell>
                                    <TableCell align="left">{row.email}</TableCell>
                                    <TableCell align="left">
                                        <NavLink
                                            title="Edit Physiotherapist"
                                            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                            to={`/physiotherapist-edit/${row.id}`}
                                        >
                                            <span className="svg-icon svg-icon-md svg-icon-primary">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                                />
                                            </span>
                                        </NavLink>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[Constants.rowsPerPage]}
                    component="div"
                    count={totalElements}
                    rowsPerPage={Constants.rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                />
            </Card.Body>
        </Card>
    );
}

export default injectIntl(PhysiotherapistList);
