import PropTypes from "prop-types";

import useUser from "../../hooks/UseUser";

/**
 * Only allows users with one of the given roles to view its children.
 * 
 * @param {object} props
 * @param {array} roles The roles which should view the content behind this filter
 */
function RolesFilter({roles, children}) {

    const user = useUser();

    if (roles.includes(user.role)) {
        return children
    }
    return false;
}

RolesFilter.propTypes = {
    roles: PropTypes.array.isRequired
}

export default RolesFilter;
