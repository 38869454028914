import { Button, FormControlLabel, Switch, TextField } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { ErrorMessage, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import * as Yup from "yup";

import SectionHeader from "../../../components/layout/SectionHeader";
import AllergyInput from "../../../components/form-control/AllergyInput";
import { FormikErrorComponent } from "../../../components/form-control/FormikErrorComponent";
import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import * as MedicalHistoryService from "../../../services/MedicalHistoryService";
import * as Constants from "../../../Constants";
import { useHistory } from "react-router-dom";
import * as AlertService from "../../../AlertService";
import useIsNew from "../../../hooks/UseIsNew";
import { useAllergyTypes } from "../../AllergyType";
import { useChronicPathologies } from "../../Pathology";
import useLatestMedicalHistoryForPatient from "../hooks/useLatestMedicalHistoryForPatient";

function MedicalHistory(props) {

    const intl = useIntl();
    const history = useHistory()
    const allergyTypes = useAllergyTypes();
    const pathologies = useChronicPathologies();
    const [, patientId] = useIsNew("", "/visit/:id");
    // can be null if patient has no previous history
    const latestMedicalHistory = useLatestMedicalHistoryForPatient(patientId);
    const [previousMedicalHistoryValues, setPreviousMedicalHistoryValues] = useState({});

    useEffect(function prepopulateEffect() {
        if (latestMedicalHistory && allergyTypes && pathologies) {
            const previousAllergies = latestMedicalHistory.allergies
            ?.map((allergySummary) => {
                return {
                    description: allergySummary.description,
                    type: allergyTypes.find((allergyType) => allergyType.id === allergySummary.allergyTypeId)
                }
            })
            ?.filter((allergy) => allergy.type !== undefined) 
            || [];

            const previousPathologies = latestMedicalHistory.chronicPathologies
            ?.map((previousPathology) => {
                return pathologies.find((pathology) => pathology.id === previousPathology.id)
            })
            ?.filter((pathology) => pathology !== undefined)
            || [];

            const previousValues = {
                allergies: previousAllergies,
                allergyNotes: latestMedicalHistory.allergyNotes,
                chronicPathologies: previousPathologies,
                chronicPathologyNotes: latestMedicalHistory.chronicPathologyNotes,
                ongoingTherapy: latestMedicalHistory.ongoingTherapy,
                surgicalHistory: latestMedicalHistory.surgicalHistory,
                prosthesisWearer: latestMedicalHistory.prosthesisWearer,
            }

            setPreviousMedicalHistoryValues(previousValues);
        }
    }, [latestMedicalHistory, allergyTypes, pathologies]);

    const formSchema = Yup.object().shape({
        allergies: Yup.array().of(Yup.object().shape({
            type: Yup.object().nullable().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
            description: Yup.string().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        })),
        allergyNotes: Yup.string(),
        chronicPathologies: Yup.array(),
        chronicPathologyNotes: Yup.string(),
        ongoingTherapy: Yup.string(),
        surgicalHistory: Yup.string(),
        prosthesisWearer: Yup.boolean(),
    });

    async function handleSubmit(values) {
        let send = {
            ...values,
            allergies: values.allergies.map((value) => {return {allergyTypeId: value.type.id, description: value.description}}),
            chronicPathologies: values.chronicPathologies.map((value) => value.id),
        }

        let result;
        let success = false;
        result = await MedicalHistoryService.addMedicalHistory(patientId, send);
        if (result.status === Constants.STATUTS_201) {
            history.push("/patient-monitored-list");
            success = true;
        }

        if (success) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await result.json()).message);
        }
        
    }

    return (
        <Card>
            <Formik
                initialValues={{
                    allergies: previousMedicalHistoryValues?.allergies || [],
                    allergyNotes: previousMedicalHistoryValues?.allergyNotes || "",
                    chronicPathologies: previousMedicalHistoryValues?.chronicPathologies || [],
                    chronicPathologyNotes: previousMedicalHistoryValues?.chronicPathologyNotes || "",
                    ongoingTherapy: previousMedicalHistoryValues?.ongoingTherapy || "",
                    surgicalHistory: previousMedicalHistoryValues?.surgicalHistory || "",
                    prosthesisWearer: previousMedicalHistoryValues?.prosthesisWearer || false,
                }}
                onSubmit={handleSubmit}
                validationSchema={formSchema}
                enableReinitialize
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Card.Body>
                            <SectionHeader messageId="GENERAL.ALLERGIES"/>
                            <AllergyInput
                                allergyTypes={allergyTypes}
                            />
                            <Row>
                                <Col sm={12}>
                                    <TextField
                                        label={intl.formatMessage({id: "FIELD.NOTES"})}
                                        {...formik.getFieldProps("allergyNotes")}
                                        multiline
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"allergyNotes"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <SectionHeader messageId="GENERAL.CHRONIC_PATHOLOGIES"/>
                            <Row>
                                <Col md={6}>
                                <FormikAutocomplete
                                        options={pathologies || []}
                                        fieldName="chronicPathologies"
                                        formik={formik}
                                        multiple
                                        labelName="name"
                                        groupKey="category"
                                        label={intl.formatMessage({ id: 'FIELD.PATHOLOGY' })}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"chronicPathologies"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <TextField
                                        label={intl.formatMessage({id: "FIELD.NOTES"})}
                                        {...formik.getFieldProps("chronicPathologyNotes")}
                                        multiline
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"chronicPathologyNotes"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <SectionHeader messageId="GENERAL.ONGOING_THERAPY"/>
                            <Row>
                                <Col md={12}>
                                    <TextField
                                        label={intl.formatMessage({id: "FIELD.NOTES"})}
                                        {...formik.getFieldProps("ongoingTherapy")}
                                        multiline
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"ongoingTherapy"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <SectionHeader messageId="GENERAL.SURGICAL_HISTORY"/>
                            <Row>
                                <Col sm={6}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formik.values.prosthesisWearer}
                                                name="prosthesisWearer"
                                                onChange={formik.handleChange}
                                                color="primary"
                                                margin="normal"
                                            />
                                        }
                                        label={intl.formatMessage({id: "FIELD.PROSTHESIS_WEARER"})}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <TextField
                                        label={intl.formatMessage({id: "FIELD.NOTES"})}
                                        {...formik.getFieldProps("surgicalHistory")}
                                        multiline
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"surgicalHistory"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={formik.isSubmitting}
                                endIcon={<Save/>}
                            >
                                <FormattedMessage id="ACTIONS.SAVE" />
                            </Button>
                        </Card.Footer>
                    </form>
                )}
            </Formik>
        </Card>
    )
}

export default injectIntl(MedicalHistory);
