import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import SVG from "react-inlinesvg";
import { Link, NavLink } from "react-router-dom";

import { mapFromArray } from "../../../Utils";
import * as Constants from "../../../Constants";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useLatestVisits } from "../../Visit";
import RolesFilter from "../../../components/layout/RolesFilter";

function PatientTable({intl, rows, teams, totalElements, page, handleChangePage}) {

    const [teamMap, setTeamMap] = useState(undefined);
    const latestVisits = useLatestVisits();
    const [latestVisitsMap, setLatestVisitsMap] = useState(undefined);

    useEffect(() => {
        if (teams) {
            setTeamMap(mapFromArray(teams, "id"));
        }
    }, [teams])

    useEffect(() => {
        if (latestVisits) {
            setLatestVisitsMap(mapFromArray(latestVisits, "patient.id"));
        }
    }, [latestVisits])

    function getVisitLink(patient) {
        const visit = latestVisitsMap?.get(patient.id);
        if (!patient || !visit) {
            return "#";
        }
        return "view-visit/" + visit._id;
    }

    return (
        <>
            <TableContainer>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><FormattedMessage id="TABLE.HEADER_LASTNAME" /></TableCell>
                            <TableCell align="left"><FormattedMessage id="TABLE.HEADER_FIRTSTNAME" /></TableCell>
                            <TableCell align="left"><FormattedMessage id="TABLE.HEADER_TEAM" /></TableCell>
                            <TableCell align="center"><FormattedMessage id="TABLE.HEADER_ACTIONS" /></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell align="left">{row.lastName}</TableCell>
                                <TableCell align="left">{row.firstName}</TableCell>
                                <TableCell align="left">{teamMap?.get(row.team)?.name}</TableCell>
                                <TableCell align="left" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <RolesFilter roles={[Constants.ROLE_ADMIN]}>
                                    <NavLink
                                        title="Modifica Paziente"
                                        className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                        to={`/patient-edit/${row.id}`}
                                    >
                                        <span className="svg-icon svg-icon-md svg-icon-primary">
                                            <SVG
                                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
                                            />
                                        </span>
                                    </NavLink>
                                    </RolesFilter>

                                    <RolesFilter roles={[Constants.ROLE_DOC]}>
                                        <Link
                                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                            to={`/patient-medical-history-card-detail/${row.id}`}
                                            title={intl.formatMessage({id: "PAGES.PATIENT_MEDICAL_CARD"})}
                                        >
                                            <span className="svg-icon svg-icon-lg svg-icon-primary">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-list.svg")}
                                                />
                                            </span>
                                        </Link>

                                        <NavLink
                                            title={intl.formatMessage({id: "MENU.NEW_VISIT"})}
                                            className="btn btn-icon btn-light btn-hover-warning btn-sm"
                                            to={`/visit/${row.id}`}
                                        >
                                            <span className="svg-icon svg-icon-lg svg-icon-warning">
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")} />
                                            </span>
                                        </NavLink>

                                        <Link
                                            className="btn btn-icon btn-light btn-hover-info btn-sm"
                                            to={`/diary/${row.id}`}
                                            title={intl.formatMessage({id: "PAGES.CLINICAL_DIARY"})}
                                        >
                                            <span className="svg-icon svg-icon-lg svg-icon-info">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Home/Book.svg")}
                                                />
                                            </span>
                                        </Link>

                                        <Link
                                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                            to={getVisitLink(row)}
                                            title={intl.formatMessage({id: "MENU.LATEST_VISIT"})}
                                        >
                                            <span className="svg-icon svg-icon-lg svg-icon-primary">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")}
                                                />
                                            </span>
                                        </Link>
                                    </RolesFilter>

                                    <RolesFilter roles={[Constants.ROLE_PHYSIO]}>
                                        <Link
                                            title={intl.formatMessage({id: "MENU.NEW_VISIT"})}
                                            className="btn btn-icon btn-light btn-hover-primary btn-sm"
                                            to={`/physiotherapy-visit/${row.id}`}
                                        >
                                            <span className="svg-icon svg-icon-lg svg-icon-warning">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}
                                                />
                                            </span>
                                        </Link>

                                        <Link
                                            className="btn btn-icon btn-light btn-hover-info btn-sm"
                                            to={`/diary/${row.id}`}
                                            title={intl.formatMessage({id: "PAGES.CLINICAL_DIARY"})}
                                        >
                                            <span className="svg-icon svg-icon-lg svg-icon-info">
                                                <SVG
                                                    src={toAbsoluteUrl("/media/svg/icons/Home/Book.svg")}
                                                />
                                            </span>
                                        </Link>
                                    </RolesFilter>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[Constants.rowsPerPage]}
                component="div"
                count={totalElements}
                rowsPerPage={Constants.rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
            />
        </>
    );
}

export default injectIntl(PatientTable);
