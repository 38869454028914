import * as Constants from "../Constants";
import { getAPIIncludeAuth, postAPIIncludeAuth } from "./Shared";


export async function getAllGlobalMedicalReports() {
    const api = Constants.BACKEND_URL + Constants.API_GLOBAL_REPORT + "?paginationEnabled=false";

    return getAPIIncludeAuth(api);
}

export async function getPreliminaryReportData({...values}) {
    let api = Constants.BACKEND_URL + Constants.API_GLOBAL_REPORT + `/data`;
    if (values.gpId) {
        api += `?gpId=${values.gpId}`;
    }
    return getAPIIncludeAuth(
        api,
        {
            "Accept": "application/json",
        }
    )
}

export async function insertGlobalMedicalReport({...values}) {
    const api = Constants.BACKEND_URL + Constants.API_GLOBAL_REPORT;
    return postAPIIncludeAuth(
        api,
        JSON.stringify(values),
        {
            "Accept": "application/json",
            'Content-Type': 'application/json',
        }
    )
}

export async function findReportsByFilter(filter) {
    let api = Constants.BACKEND_URL + Constants.API_GLOBAL_REPORT + `?paginationEnabled=true&pageNumber=${filter.page}&pageSize=${filter.limit}`;
    api += filter.grandPrix ? `&grandPrix=${filter.grandPrix}` : "";
    return getAPIIncludeAuth(api);
}
