import * as Constants from "../Constants";
import { getAPIIncludeAuth, postAPIIncludeAuth } from "./Shared"

export async function printReport({reportId, ...values}) {
    let api = Constants.BACKEND_URL + Constants.API_REPORT + `/print/${reportId}?`;
    api += `&showTimes=${values.showTimes || false}`;
    api += `&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`
    api += values.globalReportId ? `&globalReportId=${values.globalReportId}` : "";
    return getAPIIncludeAuth(
        api,
        {
            "Accept": "application/pdf",
            'Content-Type': 'application/json',
        },
    );
}

export async function getPreliminaryReportData({teamId, ...values}) {
    let api = Constants.BACKEND_URL + Constants.API_REPORT + `/data/${teamId}`;
    if (values.gpId) {
        api += `?gpId=${values.gpId}`;
    }
    return getAPIIncludeAuth(
        api,
        {
            "Accept": "application/json",
        }
    )
}

export async function insertMedicalReport({teamId, ...values}) {
    const api = Constants.BACKEND_URL + Constants.API_REPORT + `/${teamId}`;
    return postAPIIncludeAuth(
        api,
        JSON.stringify(values),
        {
            "Accept": "application/json",
            'Content-Type': 'application/json',
        }
    )
}

export async function findReportsByFilter(filter) {
    let api = Constants.BACKEND_URL + Constants.API_REPORT + `?paginationEnabled=true&pageNumber=${filter.page}&pageSize=${filter.limit}`;
    api += filter.team ? `&team=${filter.team}` : "";
    api += filter.grandPrix ? `&grandPrix=${filter.grandPrix}` : "";
    return getAPIIncludeAuth(api);
}
