import React from "react";
import "../common/svgPointerEventsReset.scss";
import Chart from "react-apexcharts";
import { arrayZip } from "../../Utils";

export function VisitStatsPieChart({ stats, setSubStat, subStatField }) {
  let entries = Object.entries(stats || {})
    .map(([entryName, entryValue]) => [entryName, entryValue.amount])
    .sort((a, b) => b[1] - a[1]);
  entries = entries.length > 0 ? entries : null;

  const [labels, values] = entries ? arrayZip(...entries) : [[], []];

  return (
    stats !== null && (
      <Chart
        className="receives-events"
        options={{
          labels: labels,
          legend: {
            position: "bottom",
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: 20,
                minAngleToShowLabel: 5,
              },
              donut: {
                labels: {
                  show: true,
                  total: { show: true },
                },
              },
            },
          },
          chart: {
            events: {
              // config.w.config.labels[config.dataPointIndex]
              dataPointSelection: (event, chartContext, config) =>
                setSubStat &&
                setSubStat(
                  stats[config.w.config.labels[config.dataPointIndex]][subStatField]
                ),
            },
          },
        }}
        series={values}
        type="donut"
      />
    )
  );
}
