import { Button, TextareaAutosize, Typography } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import * as Yup from "yup";
import * as AlertService from "../../../AlertService";
import * as Constants from "../../../Constants";
import { VisitStatsPieChart } from "../../../components/data-display/VisitStatsPieChart";
import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import { FormikErrorComponent } from "../../../components/form-control/FormikErrorComponent";
import * as GlobalReportService from "../../../services/GlobalReportService";
import { useGrandsPrix } from "../../GrandPrix";
import { GlobalReportDataDisplayTable } from "../components/GlobalReportDataDisplayTable";

function GlobalReportPage(props) {
    const grandsPrix = useGrandsPrix();
    const intl = useIntl();
    const alert = useAlert();
    const [stats, setStats] = useState(null);

    const interventionsPerLocation = summarizeStat(stats, (stat) => stat.interventionsPerLocation)
    const interventionsPerProfession = summarizeStat(stats, (stat) => stat.interventionsPerProfession)
    const interventionsPerCategory = summarizeStat(stats, (stat) => stat.interventionsPerCategory)
    const [selectedLocationStat, setSelectedLocationStat] = useState(null)
    const [selectedProfessionStat, setSelectedProfessionStat] = useState(null)
    const [selectedCategoryStat, setSelectedCategoryStat] = useState(null)

    function summarizeStat(stats, statField) {
        return stats?.teamStats.reduce((prev, teamStat) => {
            for (const [interventionsKey, interventionsSummary] of Object.entries(statField(teamStat))) {
                if (interventionsKey in prev) {
                    prev[interventionsKey].amount += interventionsSummary.amount
                } else {
                    prev[interventionsKey] = {amount: interventionsSummary.amount, byPathology: {}}
                }
                for (const [byPathologyKey, byPathologySummary] of Object.entries(interventionsSummary.byPathology)) {
                    if (byPathologyKey in prev[interventionsKey].byPathology) {
                        prev[interventionsKey].byPathology[byPathologyKey].amount += byPathologySummary.amount
                    } else {
                        prev[interventionsKey].byPathology[byPathologyKey] = {amount: byPathologySummary.amount}
                    }
                }
            }
            return prev;
        }, {}) || null;
    }

    const formSchema = Yup.object().shape({
        gp: Yup.object().nullable(),
        comment: Yup.string().required(intl.formatMessage({id: "ERRORS.REQUIRED_FIELD"})),
    })

    async function handleSubmit(values) {
        const response = await GlobalReportService.insertGlobalMedicalReport(
            {
                gpId: values.gp?.id,
                comment: values.comment,
            }
        );
        if (response.status === Constants.STATUTS_201) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await response.json()).message);
        }
    }

    async function handleSelectionChange(formik) {
        setStats(null);
        setSelectedLocationStat(null);
        setSelectedProfessionStat(null);
        setSelectedCategoryStat(null);
        formik.setFieldValue("comment", "", false);
    }

    async function handleFetchStats(gpId) {
        const response = await GlobalReportService.getPreliminaryReportData(
            {
                gpId,
            }
        );
        if (response.status === Constants.STATUTS_200) {
            setStats(await response.json());
        }
    }


    return (
        <Card>
            <Card.Body>
                <Formik
                    initialValues={{
                        gp: null,
                        comment: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={formSchema}
                >
                    {(formik) => (
                        <>
                        <Row className="d-print-none">
                            <Col md={4}>
                                <FormikAutocomplete
                                    options={grandsPrix || []}
                                    formik={formik}
                                    fieldName="gp"
                                    labelName="name"
                                    label={intl.formatMessage({id: "FIELD.GRAND_PRIX"})}
                                    onChange={() => handleSelectionChange(formik)}
                                />
                            </Col>
                            <Col md={2}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleFetchStats(formik.values.gp?.id)}
                                >
                                    <FormattedMessage id="ACTIONS.CONFIRM"/>
                                </Button>
                            </Col>

                        </Row>
                        <Row hidden={stats === null}>
                            <Col md={6}>
                                {
                                    stats !== null &&
                                    <GlobalReportDataDisplayTable
                                        stats={stats}
                                    />
                                }
                            </Col>
                            <Col md={12}>
                                <Typography variant="h2">
                                    Location Stats
                                </Typography>
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={interventionsPerLocation}
                                    setSubStat={setSelectedLocationStat}
                                    subStatField="byPathology"
                                />
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={selectedLocationStat}
                                />
                            </Col>
                            <Col md={12}>
                                <Typography variant="h2">
                                    Category Stats
                                </Typography>
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={interventionsPerCategory}
                                    setSubStat={setSelectedCategoryStat}
                                    subStatField="byPathology"
                                />
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={selectedCategoryStat}
                                />
                            </Col>
                            <Col md={12}>
                                <Typography variant="h2">
                                    Profession Stats
                                </Typography>
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={interventionsPerProfession}
                                    setSubStat={setSelectedProfessionStat}
                                    subStatField="byPathology"
                                />
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={selectedProfessionStat}
                                />
                            </Col>
                            <Col md={12}>
                                <p className="h2 mt-2">Comment</p>
                                <TextareaAutosize
                                    name="comment"
                                    style={{width: "100%"}}
                                    rowsMin={2}
                                    {...formik.getFieldProps("comment")}
                                />
                                <ErrorMessage name={"comment"} component={FormikErrorComponent}/>
                            </Col>
                            <Col>
                                <Button
                                    variant="contained"
                                    onClick={formik.handleSubmit}
                                    disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                >
                                    <FormattedMessage id="ACTIONS.SAVE"/>
                                </Button>
                            </Col>
                        </Row>
                        </>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    )
} 

export default injectIntl(GlobalReportPage);
