import React from "react";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom"
import {Button} from "@material-ui/core"

import {dateToISODateString} from "../../../Utils";

export default function VisitViewComponent({visit}) {
    if (visit) {
        return (
            <>
            <Button
                    component={Link}
                    color="primary"
                    variant="contained"
                    margin="normal"
                    style={{ marginRight: "6px", float: "right" }}
                    to={`/visit-edit/${visit.id}`}
                >
                    Edit
                </Button>
                <Typography variant="h5">{visit.patient?.historicFirstName} {visit.patient?.historicLastName}
            , {visit.patient?.historicGender === "Maschio" ? "Male" : "Female"}, born {visit.patient?.historicBirthDate}</Typography>
                <Typography variant="body1">Diagnosis: {visit.pathology?.historicName}</Typography>
                <Typography variant="body1">{visit.findings}</Typography>
                <ol>
                    {visit.therapies.map((therapy, index) =>
                        <li key={index}>{therapy.pharmaconHistoricName} {therapy.posology}</li>
                    )}
                </ol>
                {visit.notifyTime &&
                    <Typography variant="body1">A follow-up visit is required after {(new Date(visit.notifyTime)).toUTCString()}</Typography>
                }
                <Typography variant="body1">{visit.author?.historicName}, {dateToISODateString(new Date(visit.date || visit.creationDate))}</Typography>
            </>
        )
    }
    return null;
}
