import { FormControlLabel, IconButton, Switch } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { injectIntl, useIntl } from "react-intl";
import SearchIcon from '@material-ui/icons/Search';
import * as Yup from "yup";

import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import usePagination from "../../../hooks/UsePagination";
import * as ReportService from "../../../services/ReportService";
import { useGrandsPrix } from "../../GrandPrix";
import { useTeams } from "../../Team";
import * as Constants from "../../../Constants";
import ReportTable from "../components/ReportTable";
import * as Utils from "../../../Utils";
import * as AlertService from "../../../AlertService";
import { useAlert } from "react-alert";
import useGlobalMedicalReports from "../../GlobalReport/hooks/UseGlobalMedicalReports";

function ReportListPage() {
    const {rows, page, setPage, totalElements, setFilter, commit} = usePagination(ReportService.findReportsByFilter);
    const teams = useTeams();
    const grandsPrix = useGrandsPrix();
    const [showTimes, setShowTimes] = useState(true);
    const intl = useIntl();
    const alert = useAlert();
    const globalReports = useGlobalMedicalReports();

    useEffect(() => {
        async function firstSearch() {
            commit();
        }
        firstSearch();
    }, [commit]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        commit();
    };

    async function handleSubmit(values) {
        setPage(0);
        setFilter({
            team: values.team?.id,
            grandPrix: values.grandPrix?.id,
        });
        commit();
    }
    
    async function handleDownloadReport(id) {
        let globalReportId = undefined;
        const selectedReport = rows.find((row) => row.id === id);

        const sortedFilteredReports = globalReports
          .filter(
            (globalReport) =>
              globalReport.grandPrix === selectedReport.grandPrix
          )
          .map((globalReport) => ({
            ...globalReport,
            date: new Date(globalReport.date),
          }))
          .sort((a, b) => b.date - a.date);

        if (sortedFilteredReports[0] !== undefined) {
          globalReportId = sortedFilteredReports[0].id;
        }

        const response = await ReportService.printReport(
            {
                reportId: id,
                showTimes: showTimes,
                globalReportId: globalReportId,
            }
        );
        if (response.status === Constants.STATUTS_200) {
            Utils.openPDFFromResponse(response);
        } else {
            AlertService.error(alert, (await response.json()).message);
        }
    }

    async function handleChangeShowTimes() {
        setShowTimes(Boolean(!showTimes));
    }

    const formSchema = Yup.object().shape({
        team: Yup.object().nullable(),
        grandPrix: Yup.object().nullable(),
    })

    return (
        <Card>
            <Card.Body>
                <Formik
                    initialValues={{
                        team: null,
                        grandPrix: null,
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={formSchema}
                >
                    {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col md={4}>
                                    <FormikAutocomplete
                                        options={teams || []}
                                        labelName="name"
                                        fieldName="team"
                                        formik={formik}
                                        label={`${intl.formatMessage({ id: 'FIELD.TEAM' })}`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Col>
                                <Col md={4}>
                                    <FormikAutocomplete
                                        options={grandsPrix || []}
                                        labelName="name"
                                        fieldName="grandPrix"
                                        formik={formik}
                                        label={`${intl.formatMessage({ id: 'FIELD.GRAND_PRIX' })}`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Col>
                                <Col md={4}>
                                    <IconButton type="submit" color="primary">
                                        <SearchIcon/>
                                    </IconButton>
                                </Col>
                                <Col md={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="showTimes"
                                                color="primary"
                                                margin="normal"
                                                checked={showTimes}
                                                onChange={handleChangeShowTimes}
                                            />
                                        }
                                        label="Download with times"
                                    />
                                </Col>
                            </Row>
                        </form>
                    )}
                </Formik>
                <ReportTable
                    rows={rows}
                    teams={teams}
                    grandsPrix={grandsPrix}
                    handleChangePage={handleChangePage}
                    handleDownloadReport={handleDownloadReport}
                    pageNumber={page}
                    totalElements={totalElements}
                />
            </Card.Body>
        </Card>
    )
}

export default injectIntl(ReportListPage);
