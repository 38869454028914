import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import { Card, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { Save } from "@material-ui/icons";
import { ErrorMessage, Formik } from "formik";
import QRCode from 'qrcode.react';
import { Button, TextField } from "@material-ui/core";

import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import { FormikErrorComponent } from "../../../components/form-control/FormikErrorComponent";
import SectionHeader from "../../../components/layout/SectionHeader";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import useIsNew from "../../../hooks/UseIsNew";
import { useTeams } from "../../Team";
import usePhysiotherapist from "../hooks/UsePhysiotherapist";
import * as PhysiotherapistService from "../../../services/PhysiotherapistService";
import * as Constants from "../../../Constants";
import * as AlertService from "../../../AlertService";

function PhysiotherapistPage() {
    const [isNewPhysiotherapist, existingPhysiotherapistId] = useIsNew("/physiotherapist-new", "/physiotherapist-edit/:id");
    const alert = useAlert();
    const intl = useIntl();
    const history = useHistory();
    const teams = useTeams();
    const physiotherapist = usePhysiotherapist(existingPhysiotherapistId);
    const isLoading = ((isNewPhysiotherapist && !teams) || (!isNewPhysiotherapist && (!teams || !physiotherapist)));
    const qrCode = `otpauth://totp/Patient%20monitoring:${physiotherapist?.email}?secret=${physiotherapist?.twoFactorSecret}&issuer=Formula%20medicine`;
    const [previousValues, setPreviousValues] = useState({});

    useEffect(function populateEffect() {
        if (!isNewPhysiotherapist && physiotherapist && teams) {
            const previousTeams =
            teams.filter((team) => (physiotherapist.teams.find((teamId) => team.id === teamId) !== undefined));

            const previousValues = {
                teams: previousTeams,
                lastName: physiotherapist.lastName,
                firstName: physiotherapist.firstName,
                email: physiotherapist.email,
            }

            setPreviousValues(previousValues);
        }
    }, [physiotherapist, teams, isNewPhysiotherapist]);

    useEffect(function clearEffect() {
        if (isNewPhysiotherapist) {
            setPreviousValues({});
        }
    }, [isNewPhysiotherapist])

    let passwordSchema = Yup.string();
    if (isNewPhysiotherapist) {
        passwordSchema = passwordSchema.required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' }));
    }
    const formSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        lastName: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'ERRORS.WRONG_EMAIL_FORMAT' }))
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        password: passwordSchema,
        teams: Yup.array(),
    });

    async function handleSubmit(values) {
        const send = {
            ...values,
            teams: values.teams.map(team => team.id),
        }
        let result;
        let success = false;
        if (isNewPhysiotherapist) {
            result = await PhysiotherapistService.addPhysiotherapist(send);
            if (result.status === Constants.STATUTS_201) {
                let physiotherapist = await result.json();
                history.push(`/physiotherapist-edit/${physiotherapist.id}`);
                success = true;
            }
        } else {
            result = await PhysiotherapistService.updatePhysiotherapist(existingPhysiotherapistId, send);
            if (result.status === Constants.STATUTS_200) {
                success = true;
            }
        }

        if (success) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await result.json()).message);
        }
    }

    return (
        <Card>
            {isLoading && <LoadingSpinner/>}
            <Formik
                initialValues={{
                    lastName: previousValues?.lastName || "",
                    firstName: previousValues?.firstName || "",
                    email: previousValues?.email || "",
                    password: "",
                    teams: previousValues?.teams || [],
                }}
                onSubmit={handleSubmit}
                validationSchema={formSchema}
                enableReinitialize
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Card.Body>
                            <SectionHeader messageId="GENERAL.STRUCTUR"/>
                            <Row>
                                <Col sm={6} md={4}>
                                    <TextField
                                        label={`${intl.formatMessage({ id: 'FIELD.LASTNAME' })} *`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        name="lastName"
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("lastName")}
                                    />
                                    <ErrorMessage name={"lastName"} component={FormikErrorComponent}/>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextField
                                        label={`${intl.formatMessage({ id: 'FIELD.FIRSTNAME' })} *`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        name="firstName"
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("firstName")}
                                    />
                                    <ErrorMessage name={"firstName"} component={FormikErrorComponent}/>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextField
                                        label={`${intl.formatMessage({ id: 'USER.EMAIL' })} *`}
                                        autoComplete="false"
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        name="email"
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("email")}
                                    />
                                    <ErrorMessage name={"email"} component={FormikErrorComponent}/>
                                </Col>
                                <Col sm={6} md={4}>
                                    <TextField
                                        label={intl.formatMessage({ id: 'USER.PASSWORD' }) + (isNewPhysiotherapist ? " *" : "")}
                                        type="password"
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        name="password"
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("password")}
                                    />
                                    <ErrorMessage name={"password"} component={FormikErrorComponent}/>
                                </Col>
                                <Col>
                                    <FormikAutocomplete
                                        options={teams || []}
                                        labelName="name"
                                        fieldName="teams"
                                        formik={formik}
                                        multiple
                                        label={`${intl.formatMessage({ id: 'FIELD.TEAMS' })}`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            {!isNewPhysiotherapist &&
                                <>
                                    <SectionHeader messageId="GENERAL.QR_CODE"/>
                                    <Row>
                                        <Col md={4}>
                                            <QRCode value={qrCode} size={290} level={"H"} includeMargin={true} />
                                        </Col>
                                    </Row>
                                </>
                            }
                        </Card.Body>
                        <Card.Footer>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                endIcon={<Save/>}
                            >
                                <FormattedMessage id="ACTIONS.SAVE" />
                            </Button>

                            <span className="text-muted float-right mt-4">* <FormattedMessage id="GENERAL.REQUIRED_FIELDS" /></span>
                        </Card.Footer>
                    </form>
                )}
            </Formik>
        </Card>
    )
}

export default injectIntl(PhysiotherapistPage);
