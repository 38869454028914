import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import "./FormikAutocomplete.scss"
import objectPath from "object-path";

/**
* A material-ui autocomplete that can drive formik state received from its parent.
* options can be an array of strings or an array of objects. If it is an array of objects,
* labelName must be a string equal to the name of the object's field that should show in the autocomplete,
* otherwise labelName must not be specified.
*
* Additionally, if the options are objects, the name of a field can be specified in the groupKey
* parameter. The options will be grouped based on the value of this field.
*
* Any property other than the specifically named ones is set on the TextField. These can be used
* to style the TextField inside the Autocomplete
* 
* @param {Object} props Read source for a description of prop fields
* @param {Array} props.options The options to show in the autocomplete. required
* @param {Object} props.formik A formik obtained with useFormik or any other method. required
* @param {string} props.labelName The name of the label within option objects, required if options is an array of objects
* @param {string} props.fieldName The name of the formik state field this autocomplete should set
* @param {bool} props.multiple Whether this autocomplete should allow multiple selections
* @param {string} props.groupKey The name of the field used to group options. Leave blank for no grouping.
* @param {bool} props.disabled Whether this autocomplete should be disabled and prevent editing.
* @param {Object} props.extra Any extra props are set on the TextField inside the Autocomplete
* @param {function} props.onChange A handler for change events on the underlying Autocomplete.
  It is passed the same parameters as the Autocomplete's onChange
*/
function FormikAutocomplete({
  options,
  formik,
  labelName = null,
  fieldName,
  multiple = false,
  groupKey = null,
  disabled = false,
  onChange,
  ...extra
}) {
  options = options || [];
  
  function sortFunction(a, b) {
    if (a[groupKey] < b[groupKey]){return -1;}
    else if (a[groupKey] > b[groupKey]){return 1;}
    return 0;
  }
  let optionsAreObjects = Boolean(labelName !== null);
  let shouldGroup = Boolean(groupKey !== null);
  if (!optionsAreObjects && shouldGroup) {
    throw Error("You cannot specify groupKey if labelName is not present. If your options are objects specify labelName");
  }

  return (
    <Autocomplete
      multiple={multiple}
      options={shouldGroup ? options.sort(sortFunction) : options}
      onChange={(event, value) => {
        if (value) {
          formik.setFieldValue(fieldName, value)
        } else {
          formik.setFieldValue(fieldName, objectPath.get(formik.initialValues, fieldName));
        }
        if (onChange) {
          onChange(event, value);
        }
      }}
      getOptionSelected={(option, value) => {
        return optionsAreObjects ?
          option[labelName] === value[labelName] :
          option === value;
      }}
      getOptionLabel={(option) =>
        optionsAreObjects ? option[labelName] ?? "" : option
      }
      groupBy={shouldGroup ? (option) => option[groupKey] : undefined}
      value={objectPath.get(formik.values, fieldName)}
      filterSelectedOptions
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          {...extra}
        />
      )}
      onBlur={() => formik.setFieldTouched(fieldName)}
    />
  );
}

FormikAutocomplete.propTypes = {
  options: PropTypes.array.isRequired,
  formik: PropTypes.object.isRequired,
  labelName: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  groupKey: PropTypes.string,
  disabled: PropTypes.bool,
}

export default FormikAutocomplete;
