import * as Constants from "../Constants";
import { getAPIIncludeAuth, postAPIIncludeAuth } from './Shared';

export async function addMedicalHistory(patientId, medicalHistory) {
    const api = Constants.BACKEND_URL + Constants.API_MEDICAL_HISTORY + `/${patientId}`;
    return postAPIIncludeAuth(
        api,
        JSON.stringify(medicalHistory),
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    );
}

export async function getLatestMedicalHistoryForPatient(patientId) {
    const api = Constants.BACKEND_URL + Constants.API_MEDICAL_HISTORY + `/latest/${patientId}`;
    return getAPIIncludeAuth(
        api,
        {
            "Accept": "application/json",
        }
    )
}
