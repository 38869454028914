import React from "react";

import RolesFilter from "../components/layout/RolesFilter";
import * as Constants from "../Constants";
import DoctorDashboard from "./DoctorDashboard";
import PhysiotherapistDashboardPage from "./PhysiotherapistDashboardPage";

export function DashboardPage() {
    return (
        <>
            <RolesFilter roles={[Constants.ROLE_ADMIN, Constants.ROLE_DOC]}>
                <DoctorDashboard/>
            </RolesFilter>
            <RolesFilter roles={[Constants.ROLE_PHYSIO]}>
                <PhysiotherapistDashboardPage/>
            </RolesFilter>

            <div className="row">
                {/* Escamotage per i cookie. */}
                <img alt="" src={Constants.BACKEND_URL + '/api/login/cookie?token=' + sessionStorage.getItem('loggedUserToken')}></img>
                <img alt="" src={Constants.BACKEND_URL + '/api/login/status'}></img>
            </div>
        </>
    );
}
