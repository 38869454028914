import * as Constants from "../Constants";
import { getAPIIncludeAuth, postAPIIncludeAuth, putAPIIncludeAuth } from "./Shared";

export async function addPhysiotherapist(physiotherapist) {
    const api = Constants.BACKEND_URL + Constants.API_PHYSIOTHERAPIST;
    return postAPIIncludeAuth(
        api,
        JSON.stringify(physiotherapist),
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    );
}

export async function updatePhysiotherapist(id, physiotherapist) {
    const api = Constants.BACKEND_URL + Constants.API_PHYSIOTHERAPIST + `/${id}`;
    return putAPIIncludeAuth(
        api,
        JSON.stringify(physiotherapist),
        {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    );
}

export async function findPhysiotherapistById(id) {
    const api = Constants.BACKEND_URL + Constants.API_PHYSIOTHERAPIST + `/${id}`;
    return getAPIIncludeAuth(api);
}

export async function findPhysiotherapistsByFilter(filter) {
    const api = Constants.BACKEND_URL + Constants.API_PHYSIOTHERAPIST + `?pagination_enabled=true&pageNumber=${filter.page}&pageSize=${filter.limit}`;
    return getAPIIncludeAuth(api);
}
