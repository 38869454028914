import { useEffect, useState } from "react";
import * as Constants from "../Constants";

/**
 * Uses dataSource to fetch a resource and set its value as state when available.
 * dataSource must be callable with one argument, the id of the resource to fetch.
 * The resource is null until a response is available. If the response
 * is received with a 200 status, its content is parsed as json and set as
 * the resource. Any other status causes the response to be ignored and the
 * resource to remain null.
 * 
 * Changing id or dataSource is not supported yet.
 * @param {string} resultField If the resource is not at the top level of the response,
 * use this as the name of the field from the response that contains the resource.
 */
export default function useResource(id, dataSource, resultField = undefined) {
    const [resource, setResource] = useState(null);

    useEffect(() => {
        async function fetchResource() {
            if (id) {
                const fetchResult = await dataSource(id);
                if (fetchResult.status === Constants.STATUTS_200) {
                    const obj = await fetchResult.json()
                    if (resultField) {    
                        setResource(obj[resultField]);
                    } else {
                        setResource(obj);
                    }
                }
            } else {
                setResource(null);
            }
        };
        fetchResource();
    }, [id]);

    return resource;
}
