import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";

export function GlobalReportDataDisplayTable({stats}) {

    return (
        stats &&
        <TableContainer>
            <Table
                aria-label="data display table"
                size="small"
            >
                <TableBody>
                    <TableRow>
                        <TableCell>Number of patients treated</TableCell>
                        <TableCell>{stats.numberOfPatientsTreated}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Number of interventions</TableCell>
                        <TableCell>{stats.numberOfInterventions}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Number of physiotherapist assessment requested</TableCell>
                        <TableCell>{stats.numberOfPhysiotherapistAssessmentRequested}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
