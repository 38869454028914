import React, { useEffect, useState } from "react";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import DownloadIcon from "@material-ui/icons/GetApp"

import * as Constants from "../../../Constants";
import { useLang } from "../../../../_metronic/i18n";
import { mapFromArray } from "../../../Utils";

/**
 * Displays reports in a table with date, team, grand prix and the option to download.
 * Used for both Medical reports and Physiotherapy reports.
 */
export default function ReportTable({rows, teams, grandsPrix, totalElements, pageNumber, handleChangePage, handleDownloadReport}) {

    const locale = useLang();
    const [teamMap, setTeamMap] = useState(undefined);
    const [grandPrixMap, setGrandPrixMap] = useState(undefined);

    useEffect(() => {
        if (teams) {
            setTeamMap(mapFromArray(teams, "id"));
        }
    }, [teams])

    useEffect(() => {
        if (grandsPrix) {
            setGrandPrixMap(mapFromArray(grandsPrix, "id"));
        }
    }, [grandsPrix])

    return (
        <>
        <TableContainer>
            <Table aria-label="report table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><FormattedMessage id="GENERAL.DATE" /></TableCell>
                        <TableCell align="left"><FormattedMessage id="TABLE.HEADER_TEAM" /></TableCell>
                        <TableCell align="left"><FormattedMessage id="TABLE.HEADER_GRAND_PRIX" /></TableCell>
                        <TableCell align="left"><FormattedMessage id="TABLE.HEADER_ACTIONS" /></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align="left">{new Date(row.date).toLocaleString(locale)}</TableCell>
                            <TableCell align="left">{teamMap?.get(row.team)?.name}</TableCell>
                            <TableCell align="left">{grandPrixMap?.get(row.grandPrix)?.name}</TableCell>
                            <TableCell align="left">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    endIcon={<DownloadIcon/>}
                                    onClick={() => handleDownloadReport(row.id)}
                                >Download
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[Constants.rowsPerPage]}
            component="div"
            count={totalElements}
            rowsPerPage={Constants.rowsPerPage}
            page={pageNumber}
            onChangePage={handleChangePage}
        />
        </>
    )
}
