import { Button, TextareaAutosize, Typography } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedMessage, injectIntl, useIntl } from "react-intl";
import * as Yup from "yup";
import { useAlert } from "react-alert";

import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import { useTeams } from "../../Team";
import { useGrandsPrix } from "../../GrandPrix";
import * as ReportService from "../../../services/ReportService";
import * as Constants from "../../../Constants";
import * as AlertService from "../../../AlertService";
import { FormikErrorComponent } from "../../../components/form-control/FormikErrorComponent";
import { ReportDataDisplayTable } from "../components/ReportDataDisplayTable";
import { VisitStatsPieChart } from "../../../components/data-display/VisitStatsPieChart";

function ReportPage(props) {
    const teams = useTeams();
    const grandsPrix = useGrandsPrix();
    const intl = useIntl();
    const alert = useAlert();
    const [stats, setStats] = useState(null);
    const [selectedLocationStat, setSelectedLocationStat] = useState(null)
    const [selectedProfessionStat, setSelectedProfessionStat] = useState(null)
    const [selectedCategoryStat, setSelectedCategoryStat] = useState(null)

    const formSchema = Yup.object().shape({
        team: Yup.object().nullable().required(intl.formatMessage({id: "ERRORS.REQUIRED_FIELD"})),
        gp: Yup.object().nullable(),
        generalComment: Yup.string().required(intl.formatMessage({id: "ERRORS.REQUIRED_FIELD"})),
    })

    async function handleSubmit(values) {
        const response = await ReportService.insertMedicalReport(
            {
                teamId: values.team.id,
                gpId: values.gp?.id,
                comments:{
                    generalComment: values.generalComment,
                    categoryComment: "Placeholder",
                    professionComment: "Placeholder",
                }
            }
        );
        if (response.status === Constants.STATUTS_201) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await response.json()).message);
        }
    }

    async function handleSelectionChange(formik) {
        setStats(null);
        setSelectedLocationStat(null);
        setSelectedProfessionStat(null);
        setSelectedCategoryStat(null);
        formik.setFieldValue("generalComment", "", false);
    }

    async function handleFetchStats(teamId, gpId) {
        const response = await ReportService.getPreliminaryReportData(
            {
                teamId,
                gpId,
            }
        );
        if (response.status === Constants.STATUTS_200) {
            setStats(await response.json());
        }
    }

    return (
        <Card>
            <Card.Body>
                <Formik
                    initialValues={{
                        team: null,
                        gp: null,
                        generalComment: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={formSchema}
                >
                    {(formik) => (
                        <>
                        <Row className="d-print-none">
                            <Col md={4}>
                                <FormikAutocomplete
                                    options={teams || []}
                                    formik={formik}
                                    fieldName="team"
                                    labelName="name"
                                    label={intl.formatMessage({id: "FIELD.TEAM"})}
                                    onChange={() => handleSelectionChange(formik)}
                                />
                                <FormikAutocomplete
                                    options={grandsPrix || []}
                                    formik={formik}
                                    fieldName="gp"
                                    labelName="name"
                                    label={intl.formatMessage({id: "FIELD.GRAND_PRIX"})}
                                    onChange={() => handleSelectionChange(formik)}
                                />
                            </Col>
                            <Col md={2}>
                                <Button
                                    variant="contained"
                                    onClick={() => handleFetchStats(formik.values.team.id, formik.values.gp?.id)}
                                    disabled={formik.values.team === null}
                                >
                                    <FormattedMessage id="ACTIONS.CONFIRM"/>
                                </Button>
                            </Col>

                        </Row>
                        <Row hidden={stats === null}>
                            <Col md={6}>
                                {
                                    stats !== null &&
                                    <ReportDataDisplayTable
                                        stats={stats}
                                    />
                                }
                            </Col>
                            <Col md={12}>
                                <Typography variant="h2">
                                    Location Stats
                                </Typography>
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={stats?.interventionsPerLocation || null}
                                    setSubStat={setSelectedLocationStat}
                                    subStatField="byPathology"
                                />
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={selectedLocationStat}
                                />
                            </Col>
                            <Col md={12}>
                                <Typography variant="h2">
                                    Category Stats
                                </Typography>
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={stats?.interventionsPerCategory || null}
                                    setSubStat={setSelectedCategoryStat}
                                    subStatField="byPathology"
                                />
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={selectedCategoryStat}
                                />
                            </Col>
                            <Col md={12}>
                                <Typography variant="h2">
                                    Profession Stats
                                </Typography>
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={stats?.interventionsPerProfession || null}
                                    setSubStat={setSelectedProfessionStat}
                                    subStatField="byPathology"
                                />
                            </Col>
                            <Col md={6}>
                                <VisitStatsPieChart
                                    stats={selectedProfessionStat}
                                />
                            </Col>
                            <Col md={12}>
                                <p className="h2 mt-2">General Comment</p>
                                <TextareaAutosize
                                    name="generalComment"
                                    style={{width: "100%"}}
                                    rowsMin={2}
                                    {...formik.getFieldProps("generalComment")}
                                />
                                <ErrorMessage name={"generalComment"} component={FormikErrorComponent}/>
                            </Col>
                            <Col>
                                <Button
                                    variant="contained"
                                    onClick={formik.handleSubmit}
                                    disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
                                >
                                    <FormattedMessage id="ACTIONS.SAVE"/>
                                </Button>
                            </Col>
                        </Row>
                        </>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    )
}

export default injectIntl(ReportPage);
