import { Button, FormControlLabel, Input, Switch, TextField } from "@material-ui/core";
import { Publish, Save } from '@material-ui/icons';
import { ErrorMessage, Formik } from "formik";
import React from "react";
import { useAlert } from "react-alert";
import { Card, Col, Row } from "react-bootstrap";
import { FormattedDate, FormattedMessage, injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { useRouteMatch } from "react-router-dom";
import { useSubheader } from "../../../../_metronic/layout";
import * as AlertService from "../../../AlertService";
import * as Constants from "../../../Constants";
import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import { FormikErrorComponent } from "../../../components/form-control/FormikErrorComponent";
import TherapyInput from "../../../components/form-control/TherapyInput";
import SectionHeader from "../../../components/layout/SectionHeader";
import LoadingSpinner from "../../../components/loading-spinner/LoadingSpinner";
import useIsNew from "../../../hooks/UseIsNew";
import { useQueryParams } from "../../../hooks/UseQueryParams";
import { useAcutePathologies, usePathology } from "../../../modules/Pathology";
import * as VisitService from "../../../services/VisitService";
import { useLatestMedicalHistoryForPatient } from "../../MedicalHistory";
import { usePatient } from "../../Patient";
import { useVisitLocations } from "../../VisitLocation";
import { usePharmacons } from "../../../modules/Pharmacon";
import useVisit from "../hooks/UseVisit";


/**
 * Used for both creation and editing
 */
function Visit({intl, ...props}) {
    // state
    const pathologies = useAcutePathologies();
    const visitLocations = useVisitLocations();
    const drugs = usePharmacons();
    const match = useRouteMatch();
    let isNewVisit;
    let patientId;
    let visitId;
    if (match.path === "/visit/:id") {
        isNewVisit = true;
        patientId = match.params.id;
    } else if (match.path === "/visit-edit/:id") {
        isNewVisit = false;
        visitId = match.params.id;
    }
    // Will be null if it's not a visit edit
    const visit = useVisit(visitId);
    if (!isNewVisit) {
        patientId = visit?.patient.id;
    }
    const history = useHistory()
    const alert = useAlert();
    const queryParameters = useQueryParams();
    // will be null if previousPathology is not defined
    const previousPathology = usePathology(queryParameters.get("previousPathology"));
    const patient = usePatient(patientId);
    const medicalHistory = useLatestMedicalHistoryForPatient(patientId);
    const isLoading =
        (isNewVisit && (!pathologies || !visitLocations)) ||
        (!isNewVisit && (!pathologies || !visitLocations || !visit));

    // formik
    const formSchema = Yup.object().shape({
        pathology: Yup.object().nullable().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        findings: Yup.string().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        date: Yup.string(),
        therapies: Yup.array().of(Yup.object().shape({
            pharmacon: Yup.object().nullable().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
            posology: Yup.string().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        })),
        needsFollowUp: Yup.boolean().required(),
        notifyTime: Yup.string().when("needsFollowUp", {
            is: true,
            then: Yup.string().required(intl.formatMessage({id: "ERRORS.NOTIFY_TIME_REQUIRED"})),
            otherwise: Yup.string(),
        }),
        follows: Yup.string(),
        location: Yup.object().nullable().required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        needsPhysiotherapistAttention: Yup.boolean().required(),
    });

    // set subheader
    const subheader = useSubheader();
    if (isNewVisit) {
        subheader.setTitle(intl.formatMessage({id: "PAGES.NEW_VISIT"}));
    } else {
        subheader.setTitle(intl.formatMessage({ id: "PAGES.EDIT_VISIT" }));
    }

    async function handleSubmit(values) {
        let send = {
            ...values,
            pathology: values.pathology.id,
            date: values.date ? new Date(values.date).toISOString() : undefined,
            therapies: values.therapies.map((value) => {return {pharmacon: value.pharmacon.id, posology: value.posology}}),
            notifyTime: values.needsFollowUp ? new Date(values.notifyTime).toISOString() : undefined,
            location: values.location.id,
        }
        let result;
        let success = false;
        if (isNewVisit) {
            result = await VisitService.addVisit(patientId, send);
            if (result.status === Constants.STATUTS_201) {
                history.push("/patient-monitored-list");
                success = true;
            }
        } else {
            result = await VisitService.updateVisit(visitId, send);
            if (result.status === Constants.STATUTS_200) {
                history.push("/patient-monitored-list");
                success = true;
            }
        }

        if (success) {
            AlertService.success(alert);
        } else {
            AlertService.error(alert, (await result.json()).message);
        }
    }

    return (
        <>
        <Card>
            <Card.Body>
                <h3 className="h3">
                    {Boolean(previousPathology) ?
                    <FormattedMessage id="GENERAL.FOLLOWUP"/> :
                    isNewVisit ?
                    <FormattedMessage id="GENERAL.FIRST_VISIT"/> :
                    <FormattedMessage id="GENERAL.EDIT_VISIT"/>
                    }
                </h3>
                <p>{patient?.firstName} {patient?.lastName}, {patient?.birthDate}</p>
                <p>
                    <FormattedMessage id="GENERAL.KNOWN_ALLERGIES"/>{": "}
                    {medicalHistory?.allergies.length > 0
                        ? medicalHistory.allergies.map((allergySummary) => allergySummary.description).join(", ")
                        : <FormattedMessage id="GENERAL.ALLERGIES_NONE"/>
                    }
                </p>
            </Card.Body>
        </Card>
        <Card>
            {isLoading && <LoadingSpinner />}
            <Formik
                initialValues={
                    isNewVisit ? {
                        follows: queryParameters.get("follows") || "",
                        pathology: previousPathology || null,
                        findings: "",
                        date: "",
                        therapies: [],
                        needsFollowUp: false,
                        notifyTime: "",
                        location: null,
                        needsPhysiotherapistAttention: false,
                    }:
                    {
                        follows: queryParameters.get("follows") || "",
                        pathology: pathologies?.find((pathology) => pathology.id === visit?.pathology.id) || null,
                        findings: visit?.findings || "",
                        date: visit?.date ? new Date(visit.date).toLocaleString('sv').replace(' ', 'T').substring(0, 16) : "",
                        therapies: visit?.therapies.map((therapy) => (
                            {
                                posology: therapy.posology || "",
                                pharmacon: drugs?.find((drug) => drug.id === therapy.pharmaconId) || null
                            }
                        )) || [],
                        needsFollowUp: visit?.needsFollowUp || false,
                        notifyTime: visit?.notifyTime ? new Date(visit.notifyTime).toLocaleString('sv').replace(' ', 'T').substring(0, 16) : "",
                        location: visitLocations?.find((location) => location.name === visit?.location) || null,
                        needsPhysiotherapistAttention: visit?.needsPhysiotherapistAttention || false,
                    }
                }
                validationSchema={formSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {(formik) => (
                    <form onSubmit={formik.handleSubmit}>
                        <Card.Body>
                            <SectionHeader messageId="GENERAL.EXAMINATION"/>
                            <Row>
                                <Col md={6}>
                                    <FormikAutocomplete
                                        options={pathologies || []}
                                        fieldName="pathology"
                                        formik={formik}
                                        labelName="name"
                                        groupKey="category"
                                        label={intl.formatMessage({ id: 'FIELD.PATHOLOGY' })}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        disabled={Boolean(previousPathology)}
                                    />
                                    <ErrorMessage name={"pathology"} component={FormikErrorComponent}/>
                                </Col>
                                <Col md={6}>
                                    <FormikAutocomplete
                                        options={visitLocations || []}
                                        fieldName="location"
                                        formik={formik}
                                        labelName="name"
                                        label={intl.formatMessage({ id: 'FIELD.VISIT_LOCATION' })}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"location"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <TextField
                                        label={intl.formatMessage({id: "FIELD.FINDINGS"})}
                                        {...formik.getFieldProps("findings")}
                                        multiline
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"findings"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <SectionHeader messageId="GENERAL.THERAPY"/>
                            <TherapyInput drugs={drugs}/>
                            <SectionHeader messageId="GENERAL.DATE"/>
                            <Row>
                                <Col md={4}>
                                    <TextField
                                        type={"datetime-local"}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        {...formik.getFieldProps("date")}
                                    />
                                    <ErrorMessage name={"date"} component={FormikErrorComponent}/>
                                </Col>
                            </Row>
                            <SectionHeader messageId="GENERAL.FOLLOWUP"/>
                            <Row className="align-items-center">
                                <Col md={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formik.values.needsFollowUp}
                                                name="needsFollowUp"
                                                onChange={formik.handleChange}
                                                color="primary"
                                                margin="normal"
                                                disabled={!isNewVisit}
                                            />
                                        }
                                        label={intl.formatMessage({id: "FIELD.FOLLOWUP"})}
                                    />
                                </Col>
                                <Col md={4}>
                                    <TextField
                                        type={"datetime-local"}
                                        disabled={!formik.values.needsFollowUp || !isNewVisit}
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        {...formik.getFieldProps("notifyTime")}
                                    />
                                    <ErrorMessage name={"notifyTime"} component={FormikErrorComponent}/>
                                </Col>
                                <Col md={12}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formik.values.needsPhysiotherapistAttention}
                                                name="needsPhysiotherapistAttention"
                                                onChange={formik.handleChange}
                                                color="primary"
                                                margin="normal"
                                                disabled={!isNewVisit}
                                            />
                                        }
                                        label={intl.formatMessage({id: "FIELD.NEEDS_PHYSIOTHERAPY"})}
                                    />
                                </Col>
                            </Row>
                            <SectionHeader messageId="GENERAL.EXAM_UPLOAD"/>
                            <Row>
                                <Col sm={4}>
                                    <Button
                                        component="label"
                                        color="primary"
                                        variant="contained"
                                        endIcon={<Publish/>}
                                        margin="normal"
                                    >
                                        <Input
                                            id="uploadInput"
                                            type="file"
                                            inputProps={{
                                                accept: ".pdf, .jpg",
                                            }}
                                            style={{display: "none"}}
                                            onChange={formik.handleChange}
                                        />
                                        Upload
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer>
                            <Button 
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={formik.isSubmitting}
                                endIcon={<Save/>}
                            >
                                <FormattedMessage id="ACTIONS.SAVE" />
                            </Button>
                        </Card.Footer>
                    </form>
                )}
            </Formik>
        </Card>
        </>
    )
}

export default injectIntl(Visit);
