import * as Constants from "../Constants";
import { getAPIIncludeAuth } from "./Shared";

export async function getAllInjuryClasses() {
    const api = Constants.BACKEND_URL + Constants.API_INJURY_CLASS + "?paginationEnabled=false";
    return getAPIIncludeAuth(api);
}

export async function findInjuryClassById(id) {
    const api = Constants.BACKEND_URL + Constants.API_INJURY_CLASS+`/${id}`;
    return getAPIIncludeAuth(api);
}
