import { Button, FormControlLabel, IconButton, Switch } from "@material-ui/core";
import { ErrorMessage, Formik } from "formik";
import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { injectIntl, useIntl } from "react-intl";
import SearchIcon from '@material-ui/icons/Search';
import DownloadIcon from "@material-ui/icons/GetApp"
import * as Yup from "yup";

import FormikAutocomplete from "../../../components/form-control/FormikAutocomplete";
import usePagination from "../../../hooks/UsePagination";
import * as ReportService from "../../../services/ReportService";
import * as GlobalReportService from "../../../services/GlobalReportService";
import { useGrandsPrix } from "../../GrandPrix";
import { useTeams } from "../../Team";
import * as Constants from "../../../Constants";
import * as Utils from "../../../Utils";
import * as AlertService from "../../../AlertService";
import { useAlert } from "react-alert";
import { FormikErrorComponent } from "../../../components/form-control/FormikErrorComponent";

function AdminReportListPage() {
    const {rows: reportRows, setFilter: setReportFilter, commit: commitReport} = usePagination(ReportService.findReportsByFilter);
    const {rows: globalRows, setFilter: setGlobalFilter, commit: commitGlobal} = usePagination(GlobalReportService.findReportsByFilter);
    const teams = useTeams();
    const grandsPrix = useGrandsPrix();
    const [showTimes, setShowTimes] = useState(true);
    const intl = useIntl();
    const alert = useAlert();
    const [filtersChanged, setFiltersChanged] = useState(true);

    async function handleFiltersSubmit(values) {
        setReportFilter({
            team: values.team?.id,
            grandPrix: values.grandPrix?.id,
        });
        setGlobalFilter({
            grandPrix: values.grandPrix?.id,
        })
        commitReport();
        commitGlobal();
        setFiltersChanged(false);
    }

    async function handleFiltersChange() {
        setFiltersChanged(true);
    }
    
    async function handleDownloadReport(values) {
        const response = await ReportService.printReport(
            {
                reportId: values.report.id,
                showTimes: showTimes,
                globalReportId: values.globalReport.id,
            }
        );
        if (response.status === Constants.STATUTS_200) {
            Utils.openPDFFromResponse(response);
        } else {
            AlertService.error(alert, (await response.json()).message);
        }
    }

    async function handleChangeShowTimes() {
        setShowTimes(Boolean(!showTimes));
    }

    const formSchema = Yup.object().shape({
        team: Yup.object().nullable().required(),
        grandPrix: Yup.object().nullable().required(),
    })

    const downloadSchema = Yup.object().shape({
        report: Yup.object().nullable().required(),
        globalReport: Yup.object().nullable().required(),
    })

    return (
        <Card>
            <Card.Body>
                <Formik
                    initialValues={{
                        team: null,
                        grandPrix: null,
                    }}
                    onSubmit={handleFiltersSubmit}
                    validationSchema={formSchema}
                >
                    {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            <Row
                                className="align-items-center"
                            >
                                <Col md={4}>
                                    <FormikAutocomplete
                                        options={teams || []}
                                        labelName="name"
                                        fieldName="team"
                                        formik={formik}
                                        label={`${intl.formatMessage({ id: 'FIELD.TEAM' })}`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={() => handleFiltersChange()}
                                    />
                                    <ErrorMessage name={"team"} component={FormikErrorComponent}/>
                                </Col>
                                <Col md={4}>
                                    <FormikAutocomplete
                                        options={grandsPrix || []}
                                        labelName="name"
                                        fieldName="grandPrix"
                                        formik={formik}
                                        label={`${intl.formatMessage({ id: 'FIELD.GRAND_PRIX' })}`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        onChange={() => handleFiltersChange()}
                                    />
                                    <ErrorMessage name={"grandPrix"} component={FormikErrorComponent}/>
                                </Col>
                                <Col md={4}>
                                    <IconButton type="submit" color="primary">
                                        <SearchIcon/>
                                    </IconButton>
                                </Col>
                            </Row>
                        </form>
                    )}
                </Formik>
                <Formik
                    initialValues={{
                        report: null,
                        globalReport: null,
                    }}
                    onSubmit={handleDownloadReport}
                    validationSchema={downloadSchema}
                >
                    {(formik) => (
                        <form onSubmit={formik.handleSubmit}>
                            <Row     
                                hidden={
                                    reportRows.length === 0 ||
                                    globalRows.length === 0 ||
                                    filtersChanged
                                }
                                className="align-items-center"
                            >
                                <Col md={4}>
                                    <FormikAutocomplete
                                        options={reportRows || []}
                                        labelName="date"
                                        fieldName="report"
                                        formik={formik}
                                        label={intl.formatMessage({id: "FIELD.REPORT"})}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"report"} component={FormikErrorComponent}/>
                                </Col>
                                <Col md={4}>
                                    <FormikAutocomplete
                                        options={globalRows || []}
                                        labelName="date"
                                        fieldName="globalReport"
                                        formik={formik}
                                        label={intl.formatMessage({id: "FIELD.GLOBAL_REPORT"})}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                    />
                                    <ErrorMessage name={"globalReport"} component={FormikErrorComponent}/>
                                </Col>
                                <Col md={4}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="showTimes"
                                                color="primary"
                                                margin="normal"
                                                checked={showTimes}
                                                onChange={handleChangeShowTimes}
                                            />
                                        }
                                        label="Download with times"
                                    />
                                </Col>
                                <Col md={4}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        endIcon={<DownloadIcon/>}
                                    >Download
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    )
}

export default injectIntl(AdminReportListPage);
