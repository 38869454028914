import React from "react";
import { ErrorMessage, FieldArray, Formik, useFormikContext } from "formik";
import { useIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { Button, ButtonGroup, TextField } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";

import FormikAutocomplete from "./FormikAutocomplete";
import { FormikErrorComponent } from "./FormikErrorComponent";

export default function AllergyInput({allergyTypes}) {

    const formik = useFormikContext();
    const intl = useIntl();

    return (
        <FieldArray
            name={"allergies"}
            render={arrayHelpers => (
                <>
                {formik.values.allergies.map( (val, index) =>
                    <Row key={index}>
                        <Col md={4}>
                            <FormikAutocomplete
                                options={allergyTypes || []}
                                fieldName={`allergies.${index}.type`}
                                formik={formik}
                                labelName="name"
                                label={intl.formatMessage({id: "FIELD.ALLERGY_TYPE"})}
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <ErrorMessage name={`allergies.${index}.type`} component={FormikErrorComponent}/>
                        </Col>
                        <Col>
                            <TextField
                                label={intl.formatMessage({id: "FIELD.ALLERGY_DESCRIPTION"})}
                                {...formik.getFieldProps(`allergies[${index}].description`)}
                                onChange={formik.handleChange}
                                value={formik.values.allergies[index].description}
                                multiline
                                size="small"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                            />
                            <ErrorMessage name={`allergies[${index}].description`} component={FormikErrorComponent}/>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col>
                        <ButtonGroup color="primary" margin="normal">
                            <Button variant="outlined" onClick={() => arrayHelpers.push({description: ""})}><Add/></Button>
                            <Button variant="outlined" onClick={() => arrayHelpers.pop()}><Remove/></Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                </>
            )}
        />
    )
}
